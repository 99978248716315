<template>
    <div class="w-50">
        <h1 class="pb-5"> {{ $t('navigation.export_center') }} </h1>
        <FormItem id="vname" :label="$t('navigation.voyages')" type="livesearch" :service="voyageService" v-model="item.voyage_id" @onchange="getVoyageData" sortField="id" />
        <FormItem type="select" id="bld3" v-model="item.port_of_loading_id" :options="polpodOptions" placeholder="All" :label="$t('sales.loading_ports')" :disabled="loading" />
        <FormItem type="select" id="bld4" v-model="item.port_of_discharge_id" :options="polpodOptions" placeholder="All" :label="$t('sales.discharge_ports')" :disabled="loading" />
        <FormItem type="select" id="bld5" v-model="item.customer_id" :options="customers" placeholder="All" :label="$t('navigation.customers')" :disabled="loading" />
        <div style="padding-left: calc(33.3% + .5rem)">
            <FormItem type="download" id="bld9" :service="download" placeholder="All" :label="$t('operations.download_export')" startTag="fa" :options="{send: true}" :disabled="loading" />
        </div>
    </div>

</template>

<script>
    import VoyageService from '@/services/VoyageService';

    export default {
        data() {
            return {
                item: {
                    voyage_id: null,
                    port_of_loading_id: null,
                    port_of_discharge_id: null,
                    customer_id: null
                },
                polpodOptions: [],
                customers: [],
                voyageName: '',
                loading: true,
                saving: false,
                voyageService: VoyageService
            }
        },
        methods: {
            getVoyageData(){
                VoyageService.getPortsAndOperators(this.item.voyage_id, true).then(response => {
                    this.voyageName = response.data.number;
                    this.polpodOptions = response.data.ports;
                    this.polpodOptions.sort((a, b) => a.name > b.name ? 1 : -1);
                    this.customers = response.data.customers;
                    this.customers.sort((a, b) => a.name > b.name ? 1 : -1);
                    this.loading = false;
                }).catch(e => console.log(e));
            },
            download(){
                return VoyageService.downloadExcelExport(this.item).then(response => {
                    this.triggerDownload(response.data, 'Export_' + this.voyageName + '_' + (new Date().getTime()) + '.xlsx');
                }).catch(error => {
                    this.toastError(error)
                })
            }
        },
        mounted() {
            this.loading = true;
        }
    }
</script>